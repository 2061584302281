import '@hotwired/turbo-rails'
import 'flowbite';

import { application } from "./controllers/application"

import TurboVisitorController from "./controllers/turbo_visitor_controller.js"
application.register("turbo-visitor", TurboVisitorController)

import LandingNavbarController from "./controllers/tailwind/landing_navbar_controller.js"
application.register("landing-navbar", LandingNavbarController)

import LandingHeroController from "./controllers/tailwind/landing_hero_controller.js"
application.register("landing-hero", LandingHeroController)

import Cookies from "./vanilla/cookie";

window.addEventListener('load', function () {
	new Cookies();
});
