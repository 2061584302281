import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["data"]

    connect() {
        let base64data = this.dataTarget.innerText;
        const pageConfigTracking = JSON.parse(atob(base64data));

        let user = pageConfigTracking?.user;
        if (pageConfigTracking?.admin_user != null) {
            console.log(`Skipping tracking. Admin with ${pageConfigTracking?.admin_user?.email} is logged in.`);
        } else {
            if (user != null) analytics.identify(user.id, user);
            analytics.page({
                locale: pageConfigTracking?.locale || 'en',
                path: document.location.pathname,
                referrer: document.referrer,
                search: document.location.search,
                title: document.title,
                url: document.location.href
            });
        }
    }
}